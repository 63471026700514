
video{
    width:100vw;
    height:70vh;
    object-fit: cover;
}

.contanierVideotext{
    display: flex;
    justify-content: center;
    align-items: center;
}

.HeroFilterFlower{
    position: absolute;
    top:8%;
    color: rgb(255, 255, 255);
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.48);
}

.HeroHeaderFlower{
    text-align: center;
    font-size: 42px;
    font-weight: 400;
    padding: 0 1em 0 1em;
}

.HeroHeaderFlower2{
   text-align: center;
   font-size: max(20px);
   font-weight: 300;
}


/* Text om blommor */

.flowerwrapp{
    display: flex;
    justify-content: center;
    margin-top: 12em;
}

.flowercontainer{
    width: max(700px);
    margin-inline:3em;
}

.headerflower{
    font-style: bold;
    font-weight: 600;
    font-size: 30px;
}

.textflower{
    font-size: 19px;
}




/* Blommor bilder  */


/* Tre bilder på blommor */

.flexcontainerflower{
   display: flex;
   justify-content: center;
   gap: 1em;
   margin-inline:1em;
   margin-top: 15em;
}

.FlowerImg{
    width:100vw;
    height:250px;
    object-fit: cover;
    background-position: center;
    background-size: cover;
}


/* Vas och text */

.head{
    font-size: 24px;
}

.gridcontainerflower2{
    display: flex;
    justify-content: center;
    gap: 2em;
    margin-inline:1em;
    margin-top: 10em;
 }

 /* .vastextcontainer{
    
 } */

.FlowerImgvas{
    width: 60vw;
    max-height:400px;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    border-radius: 1px;
}

.flowertext{
    max-width:700px;
    font-size: 18px;
}



@media screen and (max-width: 992px) {
    .flexcontainerflower{
        display: flex;
        flex-wrap:wrap;
    }
    .gridcontainerflower2{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .pakeringcontainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .imgparkingcon{
        display: flex;
        flex-wrap: wrap;
    }

 .HeroHeaderFlower{
    font-size: 32px;
    padding: 0 0.3em 0 0.3em;
 }


}










