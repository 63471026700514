.HeroImgAbout {
  width: 100vw;
  height: 80vh;
  background-image: url("/public/img/vete4.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgfilterabout {
  width: 100vw;
  height: 80vh;
  background-color: rgba(0, 0, 0, 0.182);
  text-align: center;
  color: aliceblue;
}

.heroheaderabout {
  padding: 5em 0.5em 0 0.5em;
  font-size: 40px;
}

/* Rubrik om oss */

.headerabout {
  text-align: center;
  margin: 3em 0 3em 0;
  font-size: 25px;
}

/* bild över om företaget */

.containerharvest {
  display: flex;
  justify-content: center;
}

.imgaboutharvest {
  /* width: max(500px); */
  width: max(700px);
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Om företaget text  */

.textaboutflex {
  display: flex;
  justify-content: center;
  margin-top: 5em;
}

.textaboutwrapp {
  width: max(700px);
  margin-inline: 3em;
}

.headerAbout {
  font-style: bold;
  font-weight: 600;
  font-size: 20px;
}

/* Information om gården text i kort */

.cardwrap {
  display: flex;
  justify-content: center;
  margin-inline: 2em;
  margin-top: 2em;
}

.card {
  max-width: 70rem;
  min-height: 200px;
  background-color: #d9d9d9;
  border-radius: 6px;
  padding: 1em;
  width: max(700px);
}

.headerCard {
  font-style: bold;
  font-weight: 600;
  font-size: 20px;
  padding-top: 1.5em;
  padding-left: 1.7em;
}

.textCard {
  font-style: normal;
  font-weight: 400;
  padding: 0 2em 0 2em;
}

/* Bilder på om oss sidan  */

.imgaboutcontainer {
  display: flex;
  justify-content: center;
  margin-top: 7em;
}

.imgaboutflex {
  display: flex;
  align-items: center;
  gap: 2em;
}

.imgabout {
  max-width: 400px;
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  border-radius: 1px;
}

/* Tabbel med maskiner  */

.haedertable {
  text-align: center;
}

.tablecontainer {
  display: flex;
  justify-content: center;
  margin-bottom: 3em;
}

.table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  margin-left: 3em;
  margin-right: 3em;
}

.table thead tr {
  background-color: #003366;
  color: aliceblue;
  text-align: left;
  font-weight: bold;
}

.table th,
.table td {
  padding: 12px 15px;
}

.table tbody tr {
  border-bottom: 1px solid rgb(215, 210, 210);
}

.table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.table tbody tr:last-of-type {
  border-bottom: 2px solid #003366;
}

#flex-tabell {
  display: flex;
  justify-content: center;
  margin: 0 2em 0 2em;
}

@media screen and (max-width: 780px) {
  .imgaboutflex {
    display: flex;
    flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 0.8em;
    margin-inline: 2em;
  }

  .imgabout {
    width: 100vw;
    margin-inline: 2em;
  }

  .imgaboutharvest {
    width: 90vw;
  }

  .headerCard {
    padding-top: 0.8em;
  }

  .h11 {
    padding-top: 3em;
  }
}

/* Längre text Nyköping text och rubrik 
 
.HeaderTextLFlex{
    display: flex;
    justify-content:center;
}

 .htwrapp{
    width: max(700px);
    margin-inline:3em;
}

.headerL{
    font-style: bold;
    font-weight: 600;
    font-size: 20px;
} */
