.HeroimgRent {
  width: 100vw;
  height: 70vh;
  background-image: url("/public/img/mangardsbyggnad.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Rubrik i hero bild Hyra bostad */

.Herorentfilter {
  width: 100vw;
  height: 70vh;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.452);
  color: antiquewhite;
}

.HeroHeaderRent {
  font-size: 44px;
  font-weight: 400;
  padding-top: 4em;
}

/* Längre text Nyköping text och rubrik  */

.HeaderTextLFlex {
  display: flex;
  justify-content: center;
  margin: 5em 0 5em 0;
}

.htwrapp {
  width: max(700px);
  margin-inline: 3em;
}

.headerL {
  font-style: bold;
  font-weight: 600;
  font-size: 20px;
}

/* Lista komponent  */

.listflex {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10em;
}

ul li .listheader {
  font-size: 60px;
}

ul li {
  list-style: none;
}

ul .list {
  font-style: normal;
  font-weight: 400;
}

.apartmentheader {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-top: 4em;
}

/* Kort med bild och information om lägenter  */
.flexRentCard {
  display: flex;
  flex-direction: column;
}

.cardcontainer {
  display: flex;
  justify-content: center;
}

.rentcard {
  background-color: rgb(198, 206, 212);
  margin-top: 2em;
  border-radius: 2px;
  padding: 2em;
}

.contentrentcontainer {
  display: flex;
  gap: 2em;
}

.imgrentcard {
  max-width: 600px;
  max-height: 280px;
  background-position: center;
  object-fit: cover;
  background-size: cover;
  border-radius: 1px;
}

/* Text delen för korten */

.rentContent {
  font-size: max(16px);
}

.headerRent {
  font-size: max(20px);
}

.textrent {
  max-width: 16rem;
}

.textgroup1 {
  display: flex;
  gap: max(2em);
  font-size: max(16);
}
.textgroup2 {
  display: flex;
  flex-wrap: wrap;
  gap: min(0.5em);
  font-size: max(16px);
}

@media screen and (max-width: 670px) {
  .contentrentcontainer {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .rentcard {
    padding: 0;
  }
  .rentContent {
    padding-left: 1em;
  }

  .listflex {
    flex-direction: column;
    margin-right: 1em;
  }
}
