
/* Hero bild  */

.heroimg{
    width:100vw; 
    height:120vh; 
    background-image: url("/public/img/storakungsladuhus.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    margin-bottom: 1em;
    text-align: center;
    display: flex;
    align-items: center;
}

.filterheroimg{
    width:100vw; 
    height:120vh;
    background-color: rgba(0, 0, 0, 0.278);
    color: aliceblue;
}

.herohaeder{
    padding-top: 5em;
    font-size: 42px;
}

.herotext{
    font-size: 24px;
    color: aliceblue;
}

/* Kort stycke med knapp och länk  */

.flexShort{
    display: grid;
    gap: 5em;
    grid-template-columns: repeat( auto-fit, minmax(240px,1fr));
    margin-bottom:14em;
    place-items: center;

}

.header{
    font-size:20px;
    font-weight: bold;
    padding-bottom: 0.2em;
}

.shorttext{
    max-width: 13rem;
    padding-bottom: 0.4em;
}


/* knapparna  */
.buttonmore{
    font-weight: bold;
    font-family: inika;
    font-size: 16px;
    max-width: 100;
    min-height: 33px;
    border: none;
    padding: 0.4em 1em 0.4em 1em;
}

a{
    text-decoration: none;
    color: black;
}


/* Välkommen till gården text bild höger  */


.section4{
    display: flex;
    justify-content: center;
    margin-bottom:13em;
    width:100vw;
    height:100%;
}

.card2{
    width: 100vw;
    background-color: #D0CDC5;
}

.infowrapp{
    padding: 3em;
}

.rubrikcard{
    font-size: 32px;
}

.textcard2{
    font-size: 16px;
}

.imgsection{
    width:100vw; 
    height:400px;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
    background-size: cover;
}



/* Vår historia text bild vänster  */

.section5{
    display: flex;
    justify-content: center;
    margin-bottom:7em;
}


.card1{
    width:100vw; 
    /* background-color: rgb(176, 112, 9); */
    background-color: #D0CDC5;
}

.rubrikcard{
    font-size: 32px;
}

.textcard2{
    font-size: 16px;
}

@media screen and (max-width: 992px) {
    .section5{
      display: flex;
      flex-wrap: wrap;
    }
    .section4{
      display: flex;
      flex-wrap: wrap;
    }
  
    .card2{
        height: auto;
    }
  }


  .catcontainer{
    display: flex;
    justify-content: center;
  }

  .catimg{
    width:43rem;
    max-height:280px;
    object-fit: cover;
    margin-top: 7em;
  }



/* Rubrik och text Korta stycken Djur / odling  / kontakt*/

.wrapTextHeader1{
    max-width: 300px;
    min-height: 80px;
    margin: 5em; 
}

.wrapTextHeader2{
    width: 300px;
    height: 80px;
    background-color: antiquewhite;
}



.breadtext1Header{
    font-style: bold;
    font-weight: 600;
    font-size: 20px;
}

.breadtext1{
    font-style: normal;
    font-weight: 400;
}

/* Rubrik styling Kungsladugården / Hyra bostad / om företaget */

.h1header{
    text-align: center;
    margin: 2em 0 4em 0; 
}


/* Bild liten  */

.ImgSmall{
    max-width: 500px;
    min-height:406px;
    background-position: center;
    background-repeat: none;
    object-fit: cover;
}






