/* impoterar font inika */
@import url("https://fonts.googleapis.com/css2?family=Inika:wght@400;700&display=swap");

body {
  font-family: inika;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  position: relative;
  min-height: 100vh;
}

.navbarcontainer {
  width: 100vw;
  height: 5em;
  margin: 0;
  background-color: black;
  display: flex;
  justify-content: space-between;
}

.logga {
  width: 26px;
  height: 26px;
}

.loggacontainer {
  align-self: center;
  padding-left: 1.4em;
}

nav {
  padding-right: 3em;
}

/* nav .logga{
    float: left;
    padding-left: .6em;
    border-radius: 12px;
    font-size: 1px;
  } */

nav ul {
  text-align: center;
  padding-top: 1.8em;
  margin: 0;
}

nav ul li {
  list-style: none;
  display: inline;
  padding: 0 1em 0 0;
}

nav ul li a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-weight: 400;
  font-size: max(20px);
}

#navlinkstyle {
  color: rgb(255, 255, 255);
}

#navlinkstyle:focus {
  color: rgb(255, 189, 6);
  color: #4a680a;
}

/* Footer styling  */

.footer {
  width: 100vw;
  height: auto;
  background-color: rgb(0, 0, 0);
  color: aliceblue;
  text-align: center;
  margin-top: 10rem;
  display: flex;
  justify-items: center;
  justify-content: space-around;
}

.iconcontainer {
  display: flex;
  gap: 1em;
  padding-top: 1em;
}

.linksocial {
  color: aliceblue;
}

.menubutton {
  display: none;
}

/* Error Page börjar här*/

.errorheader {
  width: 100wv;
  height: 30vh;
  background-color: rgb(255, 255, 255);
}

.error-flex {
  display: flex;
  justify-content: center;
}

.errorcontainer {
  background-color: rgb(220, 166, 4);
  margin-bottom: 14em;
  border-radius: 2px;
  max-width: 500px;
}

.errotext {
  font-size: 30px;
  font-style: bold;
  padding: 1em 1em 0 1em;
}

/* Knapp error */
.error-button {
  font-weight: bold;
  font-family: inika;
  font-size: 16px;
  max-width: 100;
  min-height: 33px;
  border: none;
  padding: 0.4em 1em 0.4em 1em;
  border-radius: 1px;
  margin: 1em 0em 2em 2em;
  cursor: pointer;
}

@media screen and (max-width: 820px) {
  nav ul li {
    display: block;
    padding-bottom: 2em;
  }

  nav ul {
    display: block;
    padding-top: 2em;
    background-color: black;
    padding: 1.4em;
  }
  nav ul li:hover {
    color: rgb(105, 5, 5);
  }

  nav {
    z-index: 1000;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    margin-top: 4.9em;
  }

  .menubutton {
    display: block;
    color: rgb(255, 255, 255);
    font-size: 20px;
    padding: 1.5em 2em 1em 0;
  }

  .navbarcontainer {
    display: flex;
    justify-content: space-between;
    float: right;
  }

  .closemenu {
    font-size: 22px;
    color: aliceblue;
    float: right;
    padding: 0.5em 0.5em 0 0;
  }

  .errorcontainer {
    margin-inline: 3em;
  }
}
