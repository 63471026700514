/* Hero bild kontakt */

.kontaktimg {
  width: 100vw;
  height: 50vh;
  background-image: url("/public/img/vete5.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  color: aliceblue;
}

.imgkontaktfilter {
  width: 100vw;
  height: 50vh;
  background-color: rgba(0, 0, 0, 0.271);
  text-align: center;
}

.imgkontaktheader {
  margin: 0;
  padding-top: 4em;
  font-size: 32px;
}

/* Kontakt rubrik och e-post  */

/* .kontakt-flex{
   
} */

.kontaktcard {
  margin: 3em 0 18em 2em;
}

.headerkontakt {
  padding-top: 0.5em;
}

.textepost {
  padding-bottom: 0.5em;
}

/* Epost och ikon */
.epostcontainer {
  display: flex;
  gap: 0.6em;
}

.epost {
  font-size: 19px;
  margin-top: 0.2em;
}

.eposticon {
  font-size: 25px;
}
